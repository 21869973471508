import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai"; // Importing close icon from React Icons

const CalendarModal = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Button
        variant="warning"
        size="lg"
        className="rounded-4"
        onClick={handleShow}
      >
        Schedule Your Call Now{" "}
      </Button>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="xl"
        fullscreen="sm-down"
      >
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <Button variant="link" onClick={handleClose} className="p-0">
              <AiOutlineClose size={25} style={{ color: "#4f4f50" }} />
            </Button>
          </div>
          <iframe
            title="Google Calendar"
            src="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3Y0r2COMw4k_hl2m96hbMnfOLoCrMGlSVt7E3VZcWEPKSCQGzJgY1K0dEa0twmEitiAF8Gb_gN?gv=true"
            style={{
              border: "1",
              width: "100%",
              height: "87vh",
            }}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalendarModal;

import React, { useState } from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { MdPlayArrow } from "react-icons/md";
import YoutubeIcon from "./../assets/youtube.svg";
import InstagramIcon from "./../assets/icons8-instagram.svg";
import TiktokIcon from "./../assets/icons8-tiktok.svg";
import { Badge, Button, Image } from "react-bootstrap";
import { CgQuote } from "react-icons/cg";
import { BsQuote } from "react-icons/bs";
import travis from "../assets/img/travis.jpg";
import stephanie from "../assets/img/stehanine.jpg";
import santy from "../assets/img/santy.jpg";

SwiperCore.use([Pagination]);

const TestimonialsSection = () => {
  const [activeAuthor, setActiveAuthor] = useState("#author1"); // Initial active author

  const handleSlideChange = (swiper) => {
    const activeSlide = swiper.slides[swiper.activeIndex];
    const bindedId = activeSlide.getAttribute("data-swiper-binded");
    setActiveAuthor(bindedId);
  };

  const testimonials = [
    {
      id: 1,
      name: "Travis John",
      profession: "@cass",
      testimonial:
        "Carré ai is disrupting the vacation rental market with a unique combination of fractional investments + vacation credits you can use to stay at their properties",
      videoLink: "https://www.youtube.com/watch?v=qfqhbJa32lI&t=151s",
    },
    {
      id: 2,
      name: "Stephanie Ferrera",
      profession: "@cass",
      testimonial:
        "This platform allows you to invest in Real Estate in different parts of the world from a super affordable price, making investment in amazing properties accessible!",
      videoLink: "https://www.instagram.com/p/C6trFCJuqST/",
    },
    {
      id: 3,
      name: "Santy G",
      profession: "@cass",
      testimonial:
        "As someone new to real estate investment, Carré's platform has been incredibly helpful. The fractional ownership concept allowed me to start small and gradually increase my investment. the customer support team is always ready to assist with any questions.",
      videoLink:
        "https://www.tiktok.com/@santyg678/video/7381175331517943082?_r=1&_t=8nFv4aLOXCZ",
    },
  ];

  const getIcon = (link) => {
    if (link.includes("youtube"))
      return (
        <img
          src={YoutubeIcon}
          alt="YouTube"
          className="icon-svg"
          style={{ width: "27px", height: "27px" }}
        />
      );
    if (link.includes("instagram"))
      return (
        <img
          src={InstagramIcon}
          alt="Instagram"
          className="icon-svg"
          style={{ width: "27px", height: "27px" }}
        />
      );
    if (link.includes("tiktok"))
      return (
        <img
          src={TiktokIcon}
          alt="TikTok"
          className="icon-svg"
          style={{ width: "27px", height: "27px" }}
        />
      );
    return <MdPlayArrow size="22" className="ms-2" />;
  };
  return (
    <section className="container">
      <div className="my-3">
        <h2 className="fw-semibold mb-4">Why people love investing with us</h2>
      </div>
      <div className="card border-0 bg-primary bg-opacity-10 position-relative overflow-hidden">
        <svg
          className="d-block position-absolute top-0 start-0 text-white "
          width="125"
          height="99"
          viewBox="0 0 125 99"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-miterlimit="10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M-17.819-8.269c-.305.249-.567.498-.814.791-1.018 1.172-1.585 2.695-2.08 4.204-4.145 12.479-9.482 29.206-10.223 42.652-.553 10.063 2.516 19.232 8.885 27.317 9.729 12.347 18.367 9.096 32.037 9.887 16.084.937 25.391 8.173 39.497 16.156 13.103 7.426 27.427 6.943 39.163-1.128 13.859-9.521 26.045-30.056 30.495-46.387 4.494-16.493 8.071-34.611.247-51.865-12.273-27.053-55.726-15.233-74.457-11.996-8.827 1.523-17.131 5.829-26.176 6.708-5.73.556-11.547-.527-17.349-.059-5.25.425-14.935.234-19.225 3.72z"></path>
          <path d="M-3.201 8.561c-.582.923-1.003 1.992-1.28 3.105-2.414 9.594-4.974 20.77-4.552 29.792.175 3.589.945 7.016 2.458 10.282 1.323 2.871 3.127 5.595 5.395 8.188 7.329 8.378 13.801 6.459 23.631 7.162 11.721.849 18.862 5.727 29.143 10.795 9.467 4.658 19.705 3.882 28.285-2.402 10.049-7.323 18.571-22.058 21.581-33.937 3.054-12.054 5.104-25.134-.742-37.438-9.074-19.188-39.54-11.981-53.836-9.828-7.199 1.084-14.979 4.409-22.468 5.463-2.429.337-4.683.483-6.588.469-2.516.234-5.002.601-7.358 1.435C6.499 3.054-.074 4.474-2.692 7.829c-.204.234-.349.483-.509.732z"></path>
          <path d="M12.237 24.598c-.189.63-.407 1.304-.48 2.007-.625 6.781-.509 12.23 1.12 16.932.625 1.787 1.44 3.545 2.763 5.288 1.134 1.494 2.487 2.944 4.043 4.35 4.915 4.423 9.249 3.823 15.226 4.423 7.344.747 12.303 3.296 18.803 5.419 5.831 1.904 11.983.806 17.393-3.662C77.329 54.2 82.186 45.28 83.771 37.854c1.6-7.617 2.109-15.658-1.745-23.025-5.904-11.293-23.355-8.774-33.229-7.66-5.555.63-12.826 3.047-18.774 4.233-2.283.454-4.203.791-5.41.894a14.19 14.19 0 0 0-5.148 2.973c-2.69 2.432-6.151 5.39-7.082 8.656-.058.234-.087.469-.146.674z"></path>
          <path d="M31.449 24.407c-3.548 2.915-5.337 11.454-3.766 16.243 2.021 6.166 4.77 4.409 10.194 5.258 9.962 1.567 16.666 6.796 24.475-2.666 8.725-10.56 1.251-23.538-11.605-23.142-5.831.176-17.756 3.676-19.298 4.306z"></path>
        </svg>
        <svg
          className="d-block position-absolute bottom-0 end-0 text-info opacity-50"
          width="140"
          height="86"
          viewBox="0 0 140 86"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.56155 84.728C3.89813 95.3168 8.77193 105.696 14.3618 114.354C31.1345 140.282 61.2598 159.203 93.1024 149.868C115.683 143.25 136.909 120.078 146.906 99.6379C157.262 78.4816 157.784 62.3169 149.314 40.7686C138.656 13.647 114.088 -10.3284 84.9291 4.90565C72.6616 11.3094 61.6112 19.2912 48.9043 25.2334C37.333 30.6501 22.7611 33.4689 12.9718 42.2129C0.148055 53.665 -1.80519 69.4006 1.56155 84.728Z"></path>
        </svg>
        <svg
          className="d-block position-absolute text-danger opacity-50"
          width="100"
          height="109"
          viewBox="0 0 100 109"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style={{ right: "60px", bottom: " 62px" }}
        >
          <path d="M13.0417 15.1485C18.7381 10.3806 25.7233 6.78779 32.3773 4.42805C52.3217 -2.62478 76.5803 -0.291377 89.9528 18.0914C99.436 31.1261 101.262 52.5761 98.137 67.8474C94.9071 83.6571 88.4295 92.6458 74.8221 100.795C57.6962 111.053 34.339 113.786 24.8896 93.3249C20.9119 84.7197 18.2546 75.7674 13.8455 67.2289C9.83276 59.4504 3.10546 51.8265 1.45115 42.988C-0.716922 31.4112 4.80452 22.0566 13.0417 15.1485Z"></path>
        </svg>
        <div className="card-body position-relative z-2 px-lg-0 py-lg-5">
          <div className="row py-2 py-sm-1 py-md-3 py-lg-3 py-xl-3">
            <div className="col-md-4 col-lg-3 offset-lg-1  mb-md-0">
              <div className="binded-content">
                <div className={`binded-item active`} id={activeAuthor}>
                  {activeAuthor === "#author1" && (
                    <div className="d-flex  align-items-center">
                      <div>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            marginLeft: "25px",
                          }}
                        >
                          <Image
                            src={travis}
                            roundedCircle
                            fluid
                            class
                            Name="p-1"
                          />
                        </div>

                        <div className="d-block">
                          <h4 className="text-center text-dark d-inline">
                            Travis John
                          </h4>

                          <a
                            href={
                              "https://www.youtube.com/watch?v=qfqhbJa32lI&t=151s"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline"
                          >
                            <Button
                              variant="link"
                              className="fw-medium text-carre-blue rounded-0 d-inline mb-2"
                            >
                              {getIcon(
                                "https://www.youtube.com/watch?v=qfqhbJa32lI&t=151s"
                              )}
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeAuthor === "#author2" && (
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            marginLeft: "25px",
                          }}
                        >
                          <Image
                            src={stephanie}
                            roundedCircle
                            fluid
                            class
                            Name="p-1"
                          />
                        </div>

                        <div className="d-block">
                          <h4 className="text-center text-dark d-inline">
                            Steph Ferrera
                          </h4>

                          <a
                            href={"https://www.instagram.com/p/C6trFCJuqST/"}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline"
                          >
                            <Button
                              variant="link"
                              className="fw-medium text-carre-blue rounded-0 d-inline mb-2"
                            >
                              {getIcon(
                                "https://www.instagram.com/p/C6trFCJuqST/"
                              )}
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeAuthor === "#author3" && (
                    <div className="d-flex align-items-center">
                      <div>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            marginLeft: "25px",
                          }}
                        >
                          <Image
                            src={santy}
                            roundedCircle
                            fluid
                            class
                            Name="p-1"
                          />
                        </div>

                        <div className="d-block">
                          <h4 className="text-center text-dark d-inline">
                            Santy G
                          </h4>
                          <a
                            href={
                              "https://www.tiktok.com/@santyg678/video/7381175331517943082?_r=1&_t=8nFv4aLOXCZ"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline"
                          >
                            <Button
                              variant="link"
                              className="fw-medium text-carre-blue rounded-0 d-inline mb-2"
                            >
                              {getIcon(
                                "https://www.tiktok.com/@santyg678/video/7381175331517943082?_r=1&_t=8nFv4aLOXCZ"
                              )}
                            </Button>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-7">
              <Swiper
                spaceBetween={40}
                loop={true}
                autoHeight={true}
                pagination={{ el: "#testimonials-bullets", clickable: true }}
                onSlideChange={(swiper) => handleSlideChange(swiper)}
              >
                {/* Testimonial slides */}
                <SwiperSlide
                  data-swiper-binded="#author1"
                  className="fs-lg text-dark fw-semibold"
                >
                  Carré ai is disrupting the vacation rental market with a
                  unique combination of fractional investments + vacation
                  credits you can use to stay at their properties
                </SwiperSlide>
                <SwiperSlide
                  data-swiper-binded="#author2"
                  className="fs-lg text-dark fw-semibold"
                >
                  This platform allows you to invest in Real Estate in different
                  parts of the world from a super affordable price, making
                  investment in amazing properties accessible!
                </SwiperSlide>
                <SwiperSlide
                  data-swiper-binded="#author3"
                  className="fs-lg text-dark fw-semibold"
                >
                  As someone new to real estate investment, Carré's platform has
                  been incredibly helpful. The fractional ownership concept
                  allowed me to start small and gradually increase my
                  investment. the customer support team is always ready to
                  assist with any questions.
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      {/* Swiper pagination (bullets) */}
      <div
        className="swiper-pagination position-relative bottom-0 pt-4 mt-2 mt-md-3"
        id="testimonials-bullets"
      ></div>
    </section>
  );
};

export default TestimonialsSection;

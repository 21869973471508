import Dropdown from "react-bootstrap/Dropdown";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import TokenIcon from "../../assets/icons/TokenIcon";
import CarreToken from "../../assets/icons/CarreToken";
import { FaMapLocation } from "react-icons/fa6";
import { PiNavigationArrowDuotone } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";

const PropertyHomeCard = ({
  images,
  href,
  category,
  location,
  price,
  badges,
  wishlistButton,
  dropdown,
  footer,
  horizontal,
  light,
  className,
  data,
  ...props
}) => {
  const horizontalClass = horizontal ? " card-horizontal" : "",
    extraClass = className ? ` ${className}` : "";
  const image = data?.image != null ? data?.image[0]?.image_url : "";
  const title = data.property_name;
  const isLongTitle = title.length > 15;
  const titleFontSize = isLongTitle ? "14px" : "inherit";

  return (
    <div
      {...props}
      className={
        light
          ? ` card card-light custom-hover`
          : ` card card-hover custom-hover shadow-md border-0 ${horizontalClass}${extraClass}`
      }
    >
      {horizontal ? (
        <div
          className="card-img position-relative w-200 md:w-full "
          style={{
            height: "249px", // Set the height of the container
            backgroundImage: `url(${data?.image.image_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="position-absolute text-center"
            style={{ right: "20px", bottom: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fw-bold text-white bg-carre-blue p-2 rounded-pill mt-1"
              style={{ fontSize: "12px" }}
            >
              sadasdsa
            </p>
          </div>
        </div>
      ) : (
        <div
          className="card-img position-relative p-2"
          style={{ width: "100%", height: "200px" }}
        >
          <img
            loading="lazy"
            className="card-img-top rounded-2"
            alt=""
            src={image}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
          <div
            className="position-absolute text-center"
            style={{ left: "15px", top: "15px", padding: "5px" }}
          >
            <p className="text-uppercase fs-xs text-white bg-carre-blue p-2 rounded-pill mt-1 fw-medium">
              {data?.market_stage_name}
            </p>
          </div>
          {data?.property_owned_token !== null && (
            <div
              className="position-absolute text-center"
              style={{ left: "20px", bottom: "0", padding: "5px" }}
            >
              <p
                className="text-uppercase fs-md text-carre-blue  bg-carre-gray p-2 rounded-pill mt-1"
                style={{ fontSize: "12px" }}
              >
                {data?.property_owned_token?.token_qty} <CarreToken />
              </p>
            </div>
          )}
        </div>
      )}
      <div className="px-3 pb-2 pt-1">
        {/* <Card.Title
            className="h5 mt-1 text-carre-black fw-bold"
            style={{
              fontSize: titleFontSize,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Card.Title> */}
        <div className="d-flex align-items-center justify-content-center text-nowrap">
          <span className="d-inline-block mx-1 px-2 fs-md">
            {data.bedroom_count}
            <BiBed className="mx-1" />
          </span>
          <span className="d-inline-block mx-1 px-2  fs-md">
            {data.bathrooms}
            <BiBath className="mx-1" />
          </span>
          <span className="d-inline-block mx-1 px-2  fs-md">
            {data.parking}
            <FaCarSide className="mx-1" />
          </span>
        </div>
        {title && (
          <p
            className="mb-2 fs-md fw-medium"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
        )}
        <p
          className={`mb-2 fs-sm `}
          // style={{
          //   maxHeight: "20px",
          //   overflow: "hidden",
          //   textOverflow: "ellipsis",
          //   whiteSpace: "nowrap",
          // }}
        >
          <IoLocationOutline />
          {`
    ${data?.address?.city_name},
        ${data?.address?.country_name}`}
        </p>

        {data?.market_stage_name !== "Secondary" ? (
          <div className="d-flex fw-medium align-items-center justify-content-between">
            <p className="fs-sm  mb-0 me-2">Available Shares</p>
            <p className="text-carre-gray-bg mb-0">
              <span className="mx-1 fs-sm text-carre-blue float-end">
                {price} <TokenIcon />
              </span>
            </p>
          </div>
        ) : (
          <div style={{ minHeight: 32 }}></div>
        )}
        {price && (
          <div className="d-flex fw-medium align-items-center justify-content-between ">
            <p className="fs-sm  mb-0 me-2">Expected Total Return</p>
            <p className="text-carre-gray-bg mb-0">
              <span className="mx-1 fs-sm text-carre-blue float-end">
                {(
                  data?.net_expected_cash_return +
                  data?.vacation_token_exceptions +
                  data?.net_appreciation
                ).toFixed(2)}{" "}
                %
              </span>
            </p>
          </div>
        )}

        {horizontal && (
          <>
            {footer && (
              <div
                className={`d-flex align-items-center justify-content-center justify-content-sm-start border-top${
                  light ? " border-light" : ""
                } pt-3 pb-2 mt-3 text-nowrap`}
              >
                {footer.map((item, indx) => {
                  return (
                    <span key={indx} className="d-inline-block me-4 fs-sm">
                      {item[1]}
                      <i
                        className={`${item[0]} ms-1 mt-n1 fs-lg text-muted`}
                      ></i>
                    </span>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      {!horizontal && (
        <>
          {footer && (
            <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap">
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.bedroom_count}
                <BiBed />
              </span>
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.bathrooms}
                <BiBath />
              </span>
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.parking}
                <FaCarSide />
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PropertyHomeCard;

import React, { useEffect, useMemo, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  FormText,
  InputGroup,
  Row,
} from "react-bootstrap";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import UserRejectSellTokenRequest from "../components/UserRejectSellTokenRequest";
import { useGetUserSellRequest } from "../hooks/useGetUserSellRequest";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdFunnel } from "react-icons/io";
import AllUserOrders from "./Dashboard/Vacation/AllUserOrders";

function UserOrderRequest() {
  const [status, setStatus] = useState("");
  const [marketStage, setMarketStage] = useState("");
  const [type, setType] = useState("");
  const [property_id, setPropertyId] = useState("");
  const { data, refetch } = useGetUserSellRequest(
    property_id,
    marketStage,
    type,
    status
  );
  const { data: filteredData, refetch: refetchFilteredData } =
    useGetUserSellRequest("", "", "", "PENDING");

  useEffect(() => {
    refetch().then();
    refetchFilteredData();
  }, [status, marketStage, type, property_id]);

  const [property, setProperty] = useState();
  const [rejectModalShow, setRejectedModalShow] = useState(false);

  function handleRejectSellModal(data) {
    setRejectedModalShow(true);
    setProperty(data?.token_selling_request);
  }

  const clearFilters = () => {
    setPropertyId("");
    setStatus("");
    setMarketStage("");
    setType("");
  };
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (data && !initialized) {
      setInitialized(true);
    }
  }, [data, initialized]);

  const memoizedOptions = useMemo(() => {
    if (!initialized || !data) return null; // Render nothing until initialized and data is available

    return [
      ...new Map(data.map((item) => [item.property?.id, item])).values(),
    ].map((uniqueData) => (
      <option key={uniqueData.property?.id} value={uniqueData.property?.id}>
        {uniqueData.property?.property_name}
      </option>
    ));
  }, [initialized]);

  const [showFilters, setShowFilters] = useState(false);

  return (
    <div>
      <>
        <p className="fw-medium fs-4 my-4 ">Property Open Orders</p>
        {filteredData && filteredData.length > 0 ? (
          <>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 ">
              {filteredData?.map((transaction) => (
                <div className="col" key={transaction?.id}>
                  <Card bg="white" text="dark" className="rounded shadow-sm">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="text-muted fs-sm">
                          {transaction.token_selling_request.market_stage}
                        </div>
                        <div className="d-inline-flex align-items-center">
                          <Badge
                            bg="carre-gray"
                            text="black"
                            className="px-2 border-1 py-1 rounded text-xs"
                          >
                            {transaction.token_selling_request.status}
                          </Badge>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        {transaction.property.property_name}
                      </div>
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="fw-medium">
                          {" "}
                          {transaction?.token_selling_request.type}
                        </div>
                        <div className="text-muted">
                          {transaction.token_selling_request.token_qty}
                          &nbsp;TOKENS
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="fw-medium"> {transaction?.type}</div>
                        <div className="text-muted">
                          {transaction?.amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button
                          variant="outline-carre-gray-bg"
                          size="sm"
                          className="fw-light"
                          onClick={() => {
                            handleRejectSellModal(transaction);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="text-muted fs-sm">
                          {moment(
                            transaction?.token_selling_request?.created_at
                          ).format("MMM D HH:mm")}{" "}
                        </div>
                        <div className="d-inline-flex align-items-center text-muted fs-sm">
                          {transaction.token_selling_request.end_period ===
                          "END_OF_DAY" ? (
                            <div>Valid : End of day</div>
                          ) : (
                            <div>Valid: Until Completed</div>
                          )}{" "}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </>
        ) : (
          <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
            No data available.
          </Alert>
        )}
        <p className="fw-medium fs-4 my-4">Property Orders Historical</p>

        {/* Show the button only on small screens */}
        <div className="d-sm-block d-md-none mt-5 mb-3">
          <Button
            variant="primary"
            size="sm"
            onClick={() => setShowFilters(true)}
          >
            <IoMdFunnel /> Filters
          </Button>
        </div>

        <Offcanvas show={showFilters} onHide={() => setShowFilters(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filters</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col lg={6} md={6}>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Label>Property</Form.Label>
                    <Form.Select
                      aria-label="Status"
                      value={property_id}
                      onChange={(e) => setPropertyId(e.target.value)}
                      name="statusFilter"
                    >
                      <option value="">ALL</option>
                      {memoizedOptions}
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      aria-label="Status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      name="statusFilter"
                    >
                      <option value="">ALL</option>
                      <option value="PENDING">Pending</option>
                      <option value="CANCELED">Canceled</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Label>Market Stage</Form.Label>
                    <Form.Select
                      aria-label="Market Stage"
                      value={marketStage}
                      onChange={(e) => setMarketStage(e.target.value)}
                      name="marketStageFilter"
                    >
                      <option value="">ALL</option>
                      <option value="SECONDARY">Secondary</option>
                      <option value="PRIMARY">Primary</option>
                      <option value="UPCOMING">Upcoming</option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Label>Order Type</Form.Label>
                    <Form.Select
                      aria-label="Type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      name="typeFilter"
                    >
                      <option value="">ALL</option>
                      <option value="BUY">Buy</option>
                      <option value="SELL">Sell</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Filters</span>
              <Button variant="link" onClick={clearFilters}>
                Clear All
              </Button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Show filters on big screens */}
        <div className="d-none d-md-block mt-2 mb-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Filters</span>
            <Button variant="link" onClick={clearFilters}>
              Clear All
            </Button>
          </div>
          <Row>
            <Col lg={6} md={6}>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Label>Property</Form.Label>
                  <Form.Select
                    aria-label="Status"
                    value={property_id}
                    onChange={(e) => setPropertyId(e.target.value)}
                    name="statusFilter"
                  >
                    <option value="">ALL</option>
                    {memoizedOptions}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    name="statusFilter"
                  >
                    <option value="">ALL</option>
                    <option value="PENDING">Pending</option>
                    <option value="CANCELED">Canceled</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Label>Market Stage</Form.Label>
                  <Form.Select
                    aria-label="Market Stage"
                    value={marketStage}
                    onChange={(e) => setMarketStage(e.target.value)}
                    name="marketStageFilter"
                  >
                    <option value="">ALL</option>
                    <option value="SECONDARY">Secondary</option>
                    <option value="PRIMARY">Primary</option>
                    <option value="UPCOMING">Upcoming</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Order Type</Form.Label>
                  <Form.Select
                    aria-label="Type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    name="typeFilter"
                  >
                    <option value="">ALL</option>
                    <option value="BUY">Buy</option>
                    <option value="SELL">Sell</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>

      {data && data.length > 0 ? (
        <>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 ">
            {data?.map((transaction) => (
              <div className="col" key={transaction?.id}>
                <Card bg="white" text="dark" className="rounded shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="text-muted fs-sm">
                        {transaction.token_selling_request.market_stage}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <Badge
                          bg="carre-gray"
                          text="black"
                          className="px-2 border-1 py-1 rounded text-xs"
                        >
                          {transaction.token_selling_request.status}
                        </Badge>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      {transaction.property.property_name}
                    </div>
                    <div className="d-flex justify-content-between align-items-center ">
                      <div className="fw-medium">
                        {" "}
                        {transaction?.token_selling_request.type}
                      </div>
                      <div className="text-muted">
                        {transaction.token_selling_request.token_qty}
                        &nbsp;TOKENS
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="fw-medium"> {transaction?.type}</div>
                      <div className="text-muted">
                        {transaction?.amount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </div>
                    {/*<div className="d-flex justify-content-between align-items-center">*/}
                    {/*  <Button*/}
                    {/*    variant="outline-carre-gray-bg"*/}
                    {/*    size="sm"*/}
                    {/*    className="fw-light"*/}
                    {/*    // onClick={() => {*/}
                    {/*    //   setShowModal(true);*/}
                    {/*    //   setWithdrawalToCancel(transaction);*/}
                    {/*    // }}*/}
                    {/*  >*/}
                    {/*    Cancel*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="text-muted fs-sm">
                        {moment(
                          transaction.token_selling_request?.created_at
                        ).format("MMM D HH:mm")}{" "}
                      </div>
                      <div className="d-inline-flex align-items-center text-muted fs-sm">
                        {transaction.token_selling_request.end_period ===
                        "END_OF_DAY" ? (
                          <div>Valid : End of day</div>
                        ) : (
                          <div>Valid: Until Completed</div>
                        )}{" "}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
          No data available.
        </Alert>
      )}

      <UserRejectSellTokenRequest
        refetch={refetchFilteredData}
        show={rejectModalShow}
        data={property}
        onHide={() => setRejectedModalShow(false)}
      />
    </div>
  );
}

export default UserOrderRequest;

import React, { forwardRef, useState } from "react";
import { Container, Table, Button, Form } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import "react-datepicker/dist/react-datepicker.css";

import {
  useGetRateCalendar,
  useCreateRateCalendar,
  useUpdateRateCalendar,
  useDeleteRateCalendar,
} from "../../../hooks/useRateCalendar";
import { useAcceptUserSellRequest } from "../../../hooks/useAcceptOrRejectUserSellReuqest";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { IoClose } from "react-icons/io5";
import DatePicker from "react-datepicker";
import GoBackButton from "../../../components/GoBackButton";

const RateCalendar = () => {
  const { register, handleSubmit, reset, control, setValue } = useForm();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "entries",
  });
  const [rateToUpdate, setRateToUpdate] = useState();
  const [updateModalOpen, setUpdateModalOpen] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteEntryId, setDeleteEntryId] = useState(null);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} ref={ref}>
      <Form.Group className="mb-3  ">
        <Form.Label>Date:</Form.Label>
        <Form.Control className="w-100" size="sm" value={value} />
      </Form.Group>
    </div>
  ));
  const params = useParams();
  const propertyId = params.property_id;
  const {
    refetch,
    data: rateCalendar,
    isLoading,
    isError,
  } = useGetRateCalendar(propertyId);
  const createMutation = useMutation(useCreateRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
      reset(); // Reset form after successful submission
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });
  const updateMutation = useMutation(useUpdateRateCalendar, {
    onSuccess: (data) => {
      refetch();
      toast.success(data.message);
      setUpdateModalOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });
  const deleteMutation = useMutation(useDeleteRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
      setDeleteModalOpen(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });
  const [newPrice, setNewPrice] = useState("");

  const onSubmitCreate = (formData) => {
    const { entries } = formData;

    const data = { id: propertyId, entries };
    createMutation.mutate(data);
  };
  const [selectedDates, setSelectedDates] = useState([]);

  const existingDates = fields.map((field) => formatDate(field.date));

  // Function to exclude selected dates from being picked
  const excludeSelectedDates = (date) => {
    const formattedDate = formatDate(date);
    return existingDates.some((d) => d.getTime() === formattedDate.getTime());
  };

  const onUpdate = (id, rateId, newData) => {
    const data = { id, rate_id: rateId, ...newData };
    updateMutation.mutate(data);
  };

  const onDelete = (id, rateId) => {
    setDeleteModalOpen(true);
    setDeleteEntryId(rateId);
    // const data = { id, rate_id: rateId };
    // deleteMutation.mutate(data);
  };

  // const onDelete = (entryId) => {
  //   setDeleteEntryId(entryId);
  //   setDeleteModalOpen(true);
  // };

  const handleDeleteConfirm = () => {
    deleteMutation.mutate({ id: propertyId, rate_id: deleteEntryId });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    return date.toISOString(); // Return ISO string format if date is valid
  }

  const isDateDisabled = (date) => {
    // Define disabled dates here (e.g., weekends)
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // Disable Sundays (0) and Saturdays (6)
  };

  return (
    <Container>
      <GoBackButton />

      <h2>Rate Calendar</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError ? (
        <p>Error loading rate calendar</p>
      ) : (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rateCalendar?.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.date}</td>
                  <td>${entry.price}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setUpdateModalOpen(true);
                        setRateToUpdate(entry);
                      }}
                    >
                      Update
                    </Button>{" "}
                    <Button
                      variant="danger"
                      onClick={() => onDelete(propertyId, entry.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h3>Add New Rate Entries</h3>
          <Form onSubmit={handleSubmit(onSubmitCreate)}>
            {fields.map((field, index) => (
              <div key={field.id} className="d-flex align-items-end">
                {/*<div className="flex-grow-1 me-2 align-self-end ">*/}
                <DatePicker
                  className="w-100"
                  customInput={<ExampleCustomInput />}
                  selected={fields[index].date}
                  onChange={(date) => {
                    const adjustedDate = new Date(date);
                    adjustedDate.setHours(adjustedDate.getHours() + 2);
                    update(index, { date: adjustedDate });
                    setValue(`entries[${index}].date`, adjustedDate, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  placeholderText="Select a date"
                />
                {/*</div>*/}

                <div className="flex-grow-1 me-2">
                  <Form.Group
                    controlId={`entries[${index}].price`}
                    className="mb-3"
                  >
                    <Form.Label>Price:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="number"
                      step="0.01"
                      {...register(`entries[${index}].price`, {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                  </Form.Group>
                </div>
                {index > -1 && (
                  <Button
                    variant="danger"
                    onClick={() => remove(index)}
                    className="mb-3"
                    size="sm"
                  >
                    <IoClose size={21} />
                  </Button>
                )}
              </div>
            ))}
            <Button
              type="button"
              onClick={() => append({ date: "", price: "" })}
            >
              Add Entry
            </Button>
            <Button type="submit" variant="primary" className="mx-2">
              Create
            </Button>
          </Form>
        </>
      )}
      <Modal
        centered
        show={deleteModalOpen}
        onHide={() => setDeleteModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={updateModalOpen}
        onHide={() => setUpdateModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{rateToUpdate?.id}</p>
          <Form.Group>
            <Form.Label>New Price:</Form.Label>
            <Form.Control
              type="number"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUpdateModalOpen(false)}>
            No
          </Button>

          <Button
            variant="primary"
            onClick={() =>
              onUpdate(propertyId, rateToUpdate?.id, {
                price: Number(newPrice),
              })
            }
            disabled={newPrice.trim() === ""}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RateCalendar;

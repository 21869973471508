import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { FaCalendar, FaCoins, FaDollarSign, FaHashtag } from "react-icons/fa";
import { useGetUserReservedTokenByPropertyId } from "../../hooks/useGetUserReservedTokens";
import UserCancelReservedTokenModal from "../Dashboard/UserCancelReservedTokenModal";
import CustomCard from "./CostumeCard";
import { MdMoreHoriz } from "react-icons/md";

function ReserveTokenPage({ id, viewMode }) {
  const [showModal, setShowModal] = useState(false);
  const [withdrawalToCancel, setWithdrawalToCancel] = useState(null);
  const [status, setStatus] = useState("ALL");

  const { data, refetch, error } = useGetUserReservedTokenByPropertyId(
    id,
    status
  );

  useEffect(() => {
    refetch().then();
  }, [id]);

  return (
    <div style={{ minHeight: "400px" }}>
      <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
        <Form.Group
          controlId="sortby"
          className="d-flex align-items-center flex-shrink-0"
        >
          <Form.Label className="text-body fs-sm me-2 mb-0 pe-1 text-nowrap">
            Status:
          </Form.Label>
          <Form.Select size="sm" onChange={(e) => setStatus(e.target.value)}>
            <option value="ALL">All</option>
            <option value="OWNED">Owned</option>
            <option value="RESERVED">Reserved</option>
            <option value="CANCELED">Canceled</option>
          </Form.Select>
        </Form.Group>

        <hr className="d-none d-sm-block w-100 mx-4" />
        <div className="d-none d-sm-flex align-items-center flex-shrink-0 text-muted">
          <MdMoreHoriz className="fi-check-circle me-2" />
          <span className="fs-sm mt-n1">{data?.length} results</span>
        </div>
      </div>
      {error?.response?.status === 401 && (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          Log in to see more data
        </Alert>
      )}
      {data?.length === 0 ? (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          No reserved share found for this property.
        </Alert>
      ) : viewMode === "table" ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Amount</th>
              <th>Share Quantity</th>
              <th>Share Price</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((transfer) => (
              <tr key={transfer.id}>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {transfer.amount}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {transfer.token_qty}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {transfer.token_price}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {new Date(transfer.created_at).toLocaleDateString("en-US")}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {transfer.status}
                </td>
                <td className="align-middle text-center">
                  <Button
                    size="sm"
                    disabled={
                      transfer?.status === "CANCELED" ||
                      transfer?.status === "OWNED"
                    }
                    onClick={() => {
                      setShowModal(true);
                      setWithdrawalToCancel(transfer);
                    }}
                  >
                    Cancel Reservation
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Row>
          {data?.map((reserve) => (
            <Col key={reserve.id} sm={6} md={4} lg={3}>
              <CustomCard
                data={[
                  {
                    icon: <FaDollarSign className="icon" />,
                    text: "Amount",
                    value: reserve.amount,
                  },
                  {
                    icon: <FaCoins className="icon" />,
                    text: "Share Quantity",
                    value: reserve.token_qty,
                  },
                  {
                    icon: <FaDollarSign className="icon" />,
                    text: "Share Price",
                    value: reserve.token_price,
                  },
                  {
                    icon: <FaCalendar className="icon" />,
                    text: "Created At",
                    value: new Date(reserve.created_at).toLocaleDateString(
                      "en-US"
                    ),
                  },
                  {
                    icon: <FaHashtag className="icon" />,
                    text: "Status",
                    value: reserve.status,
                  },
                ]}
                button={
                  <Button
                    size="sm"
                    className="w-100"
                    disabled={
                      reserve?.status === "CANCELED" ||
                      reserve?.status === "OWNED"
                    }
                    onClick={() => {
                      setShowModal(true);
                      setWithdrawalToCancel(reserve);
                    }}
                  >
                    Cancel Reservation
                  </Button>
                }
              />
            </Col>
          ))}
        </Row>
      )}

      <UserCancelReservedTokenModal
        refetch={refetch}
        show={showModal}
        data={withdrawalToCancel}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}

export default ReserveTokenPage;

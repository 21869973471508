import { Alert, Button, Card, Form, Row } from "react-bootstrap";
import { fetchWalletStats } from "../hooks/useGetWalletStats";
import { useForm } from "react-hook-form";
import moment from "moment";
import { FaInfoCircle } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GiCash, GiReceiveMoney } from "react-icons/gi";
import { TbBeach, TbSettingsDollar } from "react-icons/tb";
import {
  BsFillHouseDownFill,
  BsFillHouseGearFill,
  BsFillHouseUpFill,
} from "react-icons/bs";
import Info from "./Tooltip";

const PaymentOverview = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [walletStats, setWalletStats] = useState(null);
  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    const oneWeekAgo = moment().subtract(1, "week").format("YYYY-MM-DD");
    setValue("dateFrom", oneWeekAgo);
    setValue("dateTo", today);
    const fetchData = async () => {
      try {
        const data = await fetchWalletStats(oneWeekAgo, today);
        setWalletStats(data);
      } catch (error) {
        toast.error("Error fetching wallet stats:", error);
      }
    };
    fetchData();
  }, []);
  const onSubmit = async (formData) => {
    const { dateFrom, dateTo } = formData;
    try {
      const data = await fetchWalletStats(dateFrom, dateTo);
      setWalletStats(data);
    } catch (error) {
      console.error("Error fetching wallet stats:", error);
    }
  };
  const getIcon = (transactionType) => {
    switch (transactionType) {
      case "DEPOSIT_PAYPAL":
      case "DEPOSIT_STRIPE":
      case "DEPOSIT_WIRE":
      case "DEPOSIT_COINBASE":
      case "DEPOSIT_NOWPAYMENT":
      case "DEPOSIT_COINIFY":
      case "DEPOSIT_NATIONAL_PROCESSOR":
      case "DEPOSIT_AUTHORIZE":
        return <GiReceiveMoney style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "SELL_TOKEN":
        return <BsFillHouseDownFill style={{ color: "#e8705b" }} />;
      case "BUY_TOKEN":
        return <BsFillHouseUpFill style={{ color: "#71a171" }} />;

      case "SELL_TOKEN_FEE":
      case "BUY_TOKEN_FEE":
        return (
          <BsFillHouseGearFill style={{ color: "rgba(44,138,197,0.76)" }} />
        );

      case "DIVIDEND_PAYMENT":
        return <GiCash style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "SELL_VACATION_POINTS":
        return <TbBeach style={{ color: "rgba(44,138,197,0.76)" }} />;
      case "BUY_VACATION_POINTS":
        return <TbBeach style={{ color: "#71a171" }} />;
      case "BUY_VACATION_POINTS_FEE":
      case "SELL_VACATION_POINTS_FEE":
        return <TbSettingsDollar style={{ color: "rgba(44,138,197,0.76)" }} />;

      case "WITHDRAW_PAYPAL":
      case "WITHDRAW_BITPAY":
      case "WITHDRAW_WIRE":
        return <GiReceiveMoney style={{ color: "#e8705b" }} />;
      default:
        return null;
    }
  };

  const cards = [
    {
      class: "mx-0 mx-md-2",
      title: `Total Rental Income`,
      style: "fs-lg text-carre-blue",

      titleInfo:
        "It indicates the monetary amount that you receive since you bought your first share.",
      cardTitleValue: ` ${walletStats?.inception_price?.toLocaleString(
        "en-US",
        {
          style: "currency",
          currency: "USD",
        }
      )}`,

      values: [
        `${walletStats?.dividend_price?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`,
        `${walletStats?.property_qty}`,
        `${walletStats?.token_qty}`,
      ],
      infoValues: [
        "It indicates the monetary amount that you receive every day for all owned shares.",
        "It indicates the number of all properties that you have bought, with at least 1 share.",
        "It indicates the number of all owned shares",
      ],
      text: ["Daily Rental Income", "Number of Properties", "Number of Shares"],
    },
  ];

  return (
    <section className="py-2 py-md-4">
      {cards.map((card, index) => (
        <div key={index}>
          <Card className={` h-100 `}>
            <Card.Body className="d-flex flex-column">
              <Card.Title
                className={`${card?.style} d-flex justify-content-between fs-lg fw-medium `}
              >
                <span>
                  {card?.titleInfo !== undefined && (
                    <Info
                      classNames="fs-sm "
                      placement="top"
                      text={card?.titleInfo}
                    >
                      <span
                        style={{ marginRight: "4px" }}
                        className={`d-block text-left fw-medium fs-md  ${
                          card?.bg === "bg-carre-blue"
                            ? "text-white"
                            : "text-carre-gray-bg"
                        }`}
                      >
                        <FaInfoCircle style={{ marginBottom: 3 }} />
                      </span>
                    </Info>
                  )}
                  {card?.title}{" "}
                </span>
                <span>{card?.cardTitleValue} </span> {/* New value */}
              </Card.Title>{" "}
              <div className="mt-auto">
                {card?.bigValue ? (
                  <div className="d-flex justify-content-center h2 fw-semibold">
                    <span>{card?.bigValue}</span>
                  </div>
                ) : (
                  card?.values?.map((value, idx) => (
                    <div
                      key={idx}
                      className={`d-flex justify-content-between fs-sm`}
                    >
                      <span className="text-truncate">
                        {card?.infoValues !== undefined && (
                          <Info
                            classNames="fs-sm"
                            placement="top"
                            text={card?.infoValues[idx]}
                          >
                            <span
                              style={{
                                marginRight: "4px",
                              }}
                              className={`d-block text-left fw-medium fs-md   ${
                                card?.bg === "bg-carre-blue"
                                  ? "text-white"
                                  : "text-carre-gray-bg"
                              }`}
                            >
                              <FaInfoCircle style={{ marginBottom: 3 }} />
                            </span>
                          </Info>
                        )}
                        {card?.text[idx]}
                      </span>
                      <span className="fs-sm text-carre-blue fw-normal text-truncate">
                        {value}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
      <h5 className="card-title widget-card-title mt-4 mb-3">
        Transactions details
      </h5>
      <div className="my-2">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-wrap justify-center"
        >
          <Row>
            <Col md={5}>
              <Form.Group
                controlId="dateFrom"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">From Date</Form.Label>
                <Form.Control type="date" {...register("dateFrom")} />
              </Form.Group>
            </Col>
            <Col md={5}>
              <Form.Group
                controlId="dateTo"
                className="w-full sm:w-auto sm:mr-2 mb-2"
              >
                <Form.Label className="mr-2">To Date</Form.Label>
                <Form.Control type="date" {...register("dateTo")} />
              </Form.Group>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                variant="primary"
                type="submit"
                className="w-100 "
                style={{ marginTop: "22px" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <div>
        <div className="row justify-content-center">
          <div>
            <div>
              <div>
                {walletStats?.details.length === 0 && (
                  <Alert className="text-center fs-sm p-2 fw-semibold">
                    No data available for the selected date range.
                  </Alert>
                )}
                {walletStats?.details?.map((transfer, index) => (
                  <div key={index} className="row gy-4 mt-1">
                    <div className="col-12">
                      <div className="row align-items-center fs-sm">
                        <div className="col-8">
                          <div className="d-flex align-items-center">
                            <div style={{ flexShrink: 0 }}>
                              <div className="fs-5 bg-carre-gray text-primary rounded-2 align-items-center justify-content-center me-3">
                                <div className="p-2">
                                  {getIcon(transfer.type)}
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="fs-md card-title fw-semibold text-dark text-start">
                                {transfer.detail}{" "}
                                <span className="text-muted">
                                  {transfer?.property_name}{" "}
                                  {transfer?.order_token_qty !== 0 &&
                                    transfer?.order_token_qty}
                                </span>
                              </p>
                              <p className="text-gray m-0 fs-sm">
                                {moment(transfer.created_at).format("l")}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-4 text-end">
                          <div>
                            <p className="fs-md card-title fw-semibold text-dark">
                              {transfer.amount.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </p>
                            <p
                              className={`text-gray m-0 fs-sm ${
                                transfer.amount < 0
                                  ? "text-carre-red"
                                  : "text-carre-green"
                              }`}
                            >
                              {transfer.total_balance?.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentOverview;

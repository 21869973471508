import { useQuery } from "react-query";
import api from "../api";

export const useGetWalletStats = (dateFrom, dateTo) => {
  return useQuery(["wallet_stats", dateFrom, dateTo], () => {
    const queryParams = { date_from: dateFrom, date_to: dateTo };

    return api
      .get("/wallet-stats", { params: queryParams })
      .then((res) => res.data);
  });
};
export const fetchWalletStats = async (dateFrom, dateTo) => {
  const queryParams = { date_from: dateFrom, date_to: dateTo };
  const response = await api.get("/wallet-stats", { params: queryParams });
  return response.data;
};

import React, { useEffect, useState } from "react";
import UserRejectSellTokenRequest from "../../components/UserRejectSellTokenRequest";
import { useGetSellRequestForAdmin } from "../../hooks/useGetSellRequestForAdmin";
import {
  Button,
  Dropdown,
  Form,
  Table,
  Alert,
  Card,
  CardDeck,
  Row,
  Col,
} from "react-bootstrap";
import { MdDelete, MdMoreHoriz } from "react-icons/md";
import { useGetPropertyRequestById } from "../../hooks/useGetPropertyRequestById";
import CustomCard from "./CostumeCard";
import {
  FaCalendar,
  FaCoins,
  FaExchangeAlt,
  FaHashtag,
  FaMoneyBill,
} from "react-icons/fa";
import { useGetSellRequestForUser } from "../../hooks/useGetSellRequestForUser";

function SellTokenPage({ id, viewMode }) {
  const [status, setStatus] = useState("ALL");
  const { data, refetch, error } = useGetSellRequestForUser(id, status);
  const [property, setProperty] = useState();
  const [rejectModalShow, setRejectedModalShow] = useState(false);

  function handleRejectSellModal(data) {
    setRejectedModalShow(true);
    setProperty(data.token_selling_request);
  }

  useEffect(() => {
    refetch().then();
  }, [status, id]);

  return (
    <div className="container" style={{ minHeight: "400px" }}>
      <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
        <Form.Group
          controlId="sortby"
          className="d-flex align-items-center flex-shrink-0"
        >
          <Form.Label className="text-body fs-sm me-2 mb-0 pe-1 text-nowrap">
            Status:
          </Form.Label>
          <Form.Select size="sm" onChange={(e) => setStatus(e.target.value)}>
            <option value="ALL">All</option>
            <option value="PENDING">Pending Request</option>
            <option value="ACCEPT">Accepted</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="CANCELED">Canceled</option>
            <option value="REJECTED">Rejected</option>
            <option value="COMPLETED">Completed</option>
          </Form.Select>
        </Form.Group>

        <hr className="d-none d-sm-block w-100 mx-4" />
        <div className="d-none d-sm-flex align-items-center flex-shrink-0 text-muted">
          <MdMoreHoriz className="fi-check-circle me-2" />
          <span className="fs-sm mt-n1">{data?.length} results</span>
        </div>
      </div>
      {error?.response?.status === 401 && (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          Log in to see more data
        </Alert>
      )}
      {data?.length === 0 ? (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          No sell request found for this property.
        </Alert>
      ) : viewMode === "table" ? (
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Share Quantity</th>
                <th>Share Price ($)</th>
                <th>Type</th>

                <th>Total ($)</th>

                <th>Status</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((sellRequest) => (
                <tr key={sellRequest.token_selling_request.id}>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {sellRequest.token_selling_request.token_qty}
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {sellRequest.token_selling_request.token_price}
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {sellRequest.token_selling_request.type}
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {sellRequest.token_selling_request.total_price}
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {sellRequest.token_selling_request.status}
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {new Date(
                      sellRequest.token_selling_request.created_at
                    ).toLocaleDateString("en-US")}
                  </td>
                  <td className="align-middle text-center">
                    <Button
                      size="sm"
                      disabled={
                        sellRequest.token_selling_request?.status ===
                          "CANCELED" ||
                        sellRequest.token_selling_request?.status ===
                          "REJECTED" ||
                        sellRequest.token_selling_request?.status ===
                          "COMPLETED"
                      }
                      onClick={() => handleRejectSellModal(sellRequest)}
                    >
                      Cancel Request
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        // Card View
        <Row>
          {data?.map((sellRequest) => (
            <Col
              key={sellRequest.token_selling_request.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CustomCard
                data={[
                  {
                    icon: <FaCalendar className="icon" />,
                    text: "Created At",
                    value: new Date(
                      sellRequest.token_selling_request.created_at
                    ).toLocaleDateString("en-US"),
                  },
                  {
                    icon: <FaCoins className="icon" />,
                    text: "Share Quantity",
                    value: sellRequest.token_selling_request.token_qty,
                  },
                  {
                    icon: <FaExchangeAlt className="icon" />,
                    text: "Type",
                    value: sellRequest.token_selling_request.type,
                  },
                  {
                    icon: <FaMoneyBill className="icon" />,
                    text: "Share Price ($)",
                    value: sellRequest.token_selling_request.token_price,
                  },
                  {
                    icon: <FaCoins className="icon" />,
                    text: "Total ($)",
                    value: sellRequest.token_selling_request.total_price,
                  },
                  {
                    icon: <FaHashtag className="icon" />,
                    text: "Status",
                    value: sellRequest.token_selling_request.status,
                  },
                ]}
                button={
                  <Button
                    size="sm"
                    className="w-100"
                    disabled={
                      sellRequest.token_selling_request?.status ===
                        "CANCELED" ||
                      sellRequest.token_selling_request?.status ===
                        "REJECTED" ||
                      sellRequest.token_selling_request?.status === "COMPLETED"
                    }
                    onClick={() => handleRejectSellModal(sellRequest)}
                  >
                    Cancel Request
                  </Button>
                }
              />
            </Col>
          ))}
        </Row>
      )}

      <UserRejectSellTokenRequest
        refetch={refetch}
        show={rejectModalShow}
        data={property}
        onHide={() => setRejectedModalShow(false)}
      />
    </div>
  );
}

export default SellTokenPage;

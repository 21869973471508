import React from "react";
import {
  useGetVacationDetails,
  useGetVacationOrderBook,
  useGetVacationOrderDetails,
  useGetVacationTransfers,
} from "../../../hooks/useGetVacationDetails";
import { Col, Row } from "react-bootstrap";
import Info from "../../../components/Tooltip";
import { FaArrowDown, FaArrowUp, FaInfoCircle } from "react-icons/fa";
import moment from "moment/moment";

const VacationOrderBook = (props) => {
  const { data } = useGetVacationOrderBook();
  const { data: orderDetails } = useGetVacationOrderDetails();
  const { showTransaction } = props;
  const buyOrders = data?.filter((order) => order.type === "BUY");
  const sellOrders = data?.filter((order) => order.type === "SELL");
  const { data: transfers } = useGetVacationTransfers();

  const renderOrderRows = (orderList, orderType) => {
    return orderList?.map((order, i) => {
      const maxTokenQty = Math.max(...orderList.map((order) => order.points));
      const percentFilled = (100 / maxTokenQty) * order?.points;

      const orderRowStyle = {
        padding: "3px",
        background: `linear-gradient(270deg, ${
          orderType === "SELL" ? "rgba(255,255,255,0)" : "rgba(255,255,255,0)"
        } ${percentFilled}%, #fff ${percentFilled}%)`,
        color: "rgba(60,59,59,0.91)",
        fontSize: "14px",
        fontWeight: 500,
      };
      return (
        <div key={i} style={orderRowStyle}>
          <Row>
            <Col xs={6}>
              <span className="d-block text-left mx-2">
                {order?.point_price.toLocaleString()}
              </span>
            </Col>

            <Col xs={6} className="">
              <span className="d-block text-end">
                {order?.available_points.toLocaleString()}
              </span>
            </Col>
            {/*<Col xs={4} className="text-end">*/}
            {/*  <span>{order?.difference.toFixed(1)}</span>*/}
            {/*</Col>*/}
          </Row>
        </div>
      );
    });
  };
  return (
    <div className="mb-4">
      <>
        <p className="fw-medium fs-4 my-4">Order Book</p>
        <Col sm={12} md={12}>
          <Row className="mt-3">
            <Col xs={6}>
              <Info
                classNames="fs-sm"
                placement="top"
                text="It indicates the monetary amount required to purchase the share for a particular real estate or property asset."
              >
                <span className="d-block text-left fw-medium text-black fs-sm">
                  Price ($) <FaInfoCircle className="mb-1" />
                </span>
              </Info>
            </Col>
            <Col xs={6} className="text-end">
              <Info
                classNames="fs-sm"
                placement="top"
                text="The amount remaining from what the user wanted to buy is indicated by the colors of the filled percentage field."
              >
                <span className="d-block text-dark  fw-medium text-black fs-sm">
                  Size <FaInfoCircle className="mb-1" />
                </span>
              </Info>
            </Col>
            {/*<Col xs={4} className="text-end">*/}
            {/*  <Info*/}
            {/*    classNames="fs-sm"*/}
            {/*    placement="top"*/}
            {/*    text="It indicates the difference in monetary amount required to purchase the share for a particular real estate or property asset."*/}
            {/*  >*/}
            {/*    <span className="d-block text-left fw-medium text-black fs-sm">*/}
            {/*      Diff (%) <FaInfoCircle className="mb-1" />*/}
            {/*    </span>*/}
            {/*  </Info>*/}
            {/*</Col>*/}
          </Row>
          <div
            className="order-container"
            style={{ background: "rgba(255,174,174,0.39)" }}
          >
            {renderOrderRows(sellOrders?.reverse(), "SELL")}
          </div>
          <div
            className="fs-6 fs-sm-sm fw-medium bg-carre-gray  d-flex justify-content-between"
            style={{
              padding: "3px",
              color: "#000",
              fontSize: "17px",
            }}
          >
            <span className="text-carre-blue px-2">
              {orderDetails?.point_price}
            </span>
            <span>Last Price</span>
          </div>

          <div
            className="order-container"
            style={{ background: "rgba(195,227,248,0.52)" }}
          >
            {renderOrderRows(buyOrders?.reverse(), "BUY")}
          </div>
        </Col>
      </>
      {showTransaction && (
        <>
          <p className="fw-medium fs-4 my-4">Trade</p>

          <Row className="mt-3">
            <Col xs={6} className="text-black fw-medium fs-sm">
              <span>Price per point ($)</span>
            </Col>
            <Col xs={3} className="fw-medium text-black fs-sm">
              <span>Quantity</span>
            </Col>
            <Col xs={3} className="text-end text-black fw-medium fs-sm">
              <span>Time (UTC)</span>
            </Col>
          </Row>

          {transfers?.slice(0, 10).map((transfer, index) => (
            <Row className={index % 2 === 0 ? "bg-carre-gray" : ""}>
              <Col xs={6}>
                <span className="d-block text-left">
                  {transfer?.point_price}
                </span>
              </Col>
              <Col xs={3}>
                <span className="text-center">{transfer?.points}</span>
              </Col>

              <Col xs={3} className="text-end">
                <span className="d-block text-end">
                  {moment(transfer.created_at).format("MMM D HH:mm")}
                </span>
              </Col>
            </Row>
          ))}
        </>
      )}
    </div>
  );
};

export default VacationOrderBook;

import React from "react";
import { Accordion } from "react-bootstrap";
import contactUs from "../assets/img/contactus.jpg";

const FaqSection = () => {
  return (
    <section className="py-5">
      <div className="bg-secondary">
        <div className="d-flex flex-column flex-md-row align-items-center mb-5 container py-5">
          <div className="order-2 order-md-1 col-md-7 mb-4 mb-md-0">
            <h2 className="h1 fw-semibold">Don't see the answer you need?</h2>
            <p className="mb-sm-4 fs-lg fw-semibold">
              That's ok. Just drop a message and we will get back to you ASAP.
            </p>
            <a
              className="btn btn-warning rounded-4"
              href="mailto:info@carre.ai"
            >
              Contact us
            </a>
          </div>
          <div className="order-1 order-md-2 col-md-5 mb-4 mb-md-0">
            <div className="position-relative mb-sm-2">
              <img
                src={contactUs}
                className="rounded-4 img-fluid"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container py-md-2 py-lg-3 py-xl-5 my-2 my-sm-3 my-md-4 my-xxl-5">
        <div className="row">
          <div className="col-md-4 text-center text-md-start pb-3 pb-sm-4">
            <h2 className="h1 fw-semibold">Any questions? Check out the FAQ</h2>
            <p className="">
              If you have any questions, check our Learn section for answers.
            </p>
            {/*<Button*/}
            {/*  size="sm"*/}
            {/*  className="mb-2 rounded-4"*/}
            {/*  variant="outline-warning"*/}
            {/*>*/}
            {/*  Learn more*/}
            {/*</Button>*/}
            <div className="d-none d-md-flex justify-content-center">
              <svg
                className="text-warning ms-n4"
                width="200"
                height="211"
                viewBox="0 0 200 211"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>
          <div className="col-md-8 col-lg-7 offset-lg-1">
            {faqItems.map((item, index) => (
              <Accordion className="">
                <Accordion.Item
                  key={index}
                  eventKey={index.toString()}
                  className="mb-3"
                >
                  <Accordion.Header className="rounded-4">
                    <p
                      className="fs-md fw-semibold"
                      style={{ marginTop: -7, marginBottom: -7 }}
                    >
                      {item.question}
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>{item.answer}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const faqItems = [
  {
    question: "What is fractional real estate investing?",
    answer:
      "Fractional real estate investing is a method that allows multiple investors to purchase shares in a property. Instead of buying an entire property, investors can buy fractional ownership, making it more affordable and accessible.",
  },
  {
    question: "How does Carré work?",
    answer:
      "Customers can buy shares of properties listed on the Carré platform. Each property is divided into fractional shares, allowing investors to own a portion of the property. Investors earn daily rental income based on their ownership percentage. The rental income is collected from tenants and distributed to investors proportionally.",
  },
  {
    question:
      "What are the benefits of investing in real estate through Carré compared to traditional real estate investment?",
    answer:
      "Benefits include lower entry costs, passive income, a diversified global portfolio, professional property management, and liquidity.",
  },
  {
    question: "How do I know my investment is secure with Carré?",
    answer:
      "Carré ensures security through comprehensive due diligence processes, a robust legal structure, transparency, and rigorous property management.",
  },
  {
    question:
      "How can I track my earnings and the performance of my investments?",
    answer:
      "Carré provides a detailed dashboard with tools for income tracking, portfolio management, and performance metrics.",
  },
  {
    question:
      "What happens if I want to sell my shares? Is there a secondary market?",
    answer:
      "Carré offers a secondary market where investors can sell their shares, providing liquidity.",
  },
  {
    question: "What fees are associated with investing through Carré?",
    answer:
      "Fees include management fees, transaction fees, and other costs, which are generally lower compared to traditional real estate investing.",
  },
];

export default FaqSection;

import api from "../api";
import { useQuery } from "react-query";

export const useCreateRateCalendar = (data) => {
  const { id, entries } = data;
  return api
    .post(`/property/${id}/rate-calendar`, entries)
    .then((res) => res.data);
};

export const useGetRateCalendar = (propertyId) => {
  return useQuery(["rate_calendar", { propertyId }], () => {
    return api
      .get(`property/${propertyId}/rate-calendar`)
      .then((res) => res.data);
  });
};
export const useUpdateRateCalendar = (data) => {
  const { id, rate_id, ...data2 } = data;

  return api
    .put(`/property/${id}/rate-calendar/${rate_id}`, data2)
    .then((res) => res.data);
};
export const useDeleteRateCalendar = (data) => {
  const { id, rate_id } = data;

  return api
    .delete(`/property/${id}/rate-calendar/${rate_id}`)
    .then((res) => res.data);
};

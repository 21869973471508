import React, { useEffect, useState } from "react";
import {
  Alert,
  Table,
  Card,
  Row,
  Col,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { useGetUserTransfers } from "../../hooks/useGetUserTransfers";
import CustomCard from "./CostumeCard";
import {
  FaCalendar,
  FaCoins,
  FaDollarSign,
  FaExchangeAlt,
} from "react-icons/fa";

function TransfersPage({ id, viewMode }) {
  const { data, refetch, error } = useGetUserTransfers(id);

  useEffect(() => {
    refetch().then();
  }, [id]);

  return (
    <div style={{ minHeight: "400px" }}>
      {error?.response?.status === 401 && (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          Log in to see more data
        </Alert>
      )}
      {data?.length === 0 ? (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          No transfer found for this property.
        </Alert>
      ) : (
        <div>
          {viewMode === "table" ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Buy/Sell</th>
                  <th>Share Quantity</th>
                  <th>Share Price</th>
                  <th>Amount</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((transfer) => (
                  <tr key={transfer.id}>
                    <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                      {transfer.method}
                    </td>
                    <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                      {transfer.token_qty}
                    </td>
                    <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                      {transfer.token_price}
                    </td>
                    <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                      {transfer.amount}
                    </td>
                    <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                      {new Date(transfer.created_at).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <Row className="mb-3">
                {data?.map((transfer) => (
                  <Col key={transfer.id} sm={6} md={4} lg={3}>
                    <CustomCard
                      data={[
                        {
                          icon: <FaExchangeAlt className="icon" />,
                          text: "Buy/Sell",
                          value: transfer?.method,
                        },
                        {
                          icon: <FaCoins className="icon" />,
                          text: "Share Quantity",
                          value: transfer.token_qty,
                        },
                        {
                          icon: <FaDollarSign className="icon" />,
                          text: "Share Price",
                          value: transfer.token_price,
                        },
                        {
                          icon: <FaDollarSign className="icon" />,
                          text: "Amount",
                          value: transfer.amount,
                        },
                        {
                          icon: <FaCalendar className="icon" />,
                          text: "Created At",
                          value: new Date(
                            transfer.created_at
                          ).toLocaleDateString("en-US"),
                        },
                      ]}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TransfersPage;

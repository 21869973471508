import React, { useEffect } from "react";
import CalendarButton from "./CalendarButton";
import CalendarModal from "./CalendarButton";
import callToAction from "../assets/img/aboutUsHero.png";

const CalendarPage: React.FC = () => {
  return (
    <section className="container mt-5" data-bs-theme="dark">
      <div
        className="rounded-4"
        style={{
          backgroundImage: `url(${callToAction})`,
          minHeight: "40vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="bg-opacity-50 bg-carre-gray-bg rounded-4 w-100 d-flex justify-content-center align-items-center p-4"
          style={{ minHeight: "40vh" }}
        >
          <form className="mx-auto" style={{ maxWidth: 900 }}>
            <h2 className="h1 text-center text-white pb-4">
              Ready to Start Your Real Estate Journey?
            </h2>
            <p className="text-white text-center fs-4 fw-medium">
              Book a Free Consultation Call to Explore Your Investment
              Opportunities
            </p>
            <div className="justify-content-center align-items-center d-flex mt-5">
              <div className=" text-center">
                <CalendarModal />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>

    // <Container className="">
    //   <Row className="m-5 bg-carre-gray-bg bg-opacity-25 rounded-3">
    //     <Col className="text-center my-4">
    //       <h1 className="fs-1 fw-semibold text-black">
    //         Ready to Start Your Real Estate Journey?
    //       </h1>
    //       <p>
    //         Book a Free Consultation Call to Explore Your Investment
    //         Opportunities
    //       </p>
    //       <div id="calendar-button-container"></div>
    //     </Col>
    //   </Row>
    //   <Row className="justify-content-center">
    //     <Col md={8}></Col>
    //   </Row>
    //
    // </Container>
  );
};

export default CalendarPage;

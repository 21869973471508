import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useSubscribeUser } from "../hooks/useSubscribeUser";
import { FaTimes } from "react-icons/fa";
import "./SubscribeModal.css";

const SubscribeModal = ({ showModal, handleClose }) => {
  const { mutate, isLoading } = useMutation(useSubscribeUser, {
    onSuccess: (data) => {
      toast.success("Successfully subscribed");
      if (process.env.REACT_APP_IS_PRODUCTION !== "false") {
        // eslint-disable-next-line no-undef
        fbq("track", "Lead");
      }
      handleClose();
    },
    onError: (error) => {
      toast.error(error?.response.data.error);
    },
  });

  const [isMarketingEmailsChecked, setIsMarketingEmailsChecked] =
    useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);

  const subscribe = (email, shouldReceiveMarketingEmails) => {
    if (!shouldReceiveMarketingEmails) {
      setIsCheckboxError(true);
    } else {
      mutate(email);
      setIsCheckboxError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    subscribe(email, isMarketingEmailsChecked);
  };

  const handleMarketingEmailsCheckboxChange = () => {
    setIsMarketingEmailsChecked((prev) => !prev);
    setIsCheckboxError(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered size="lg">
      <div className="custom-modal-container">
        <FaTimes onClick={handleClose} className="custom-close-button" />
        <div className="custom-form-container bg-white rounded-2 p-4">
          <h2>Get exclusive investment insights</h2>
          <p className="text-dark">
            Subscribe to Carré's Newsletter for the latest in luxury real
            estate, investment tips, and exclusive opportunities in property
            ownership
          </p>
          <Form
            className="mt-4 flex-column align-items-center"
            onSubmit={handleSubmit}
          >
            <Form.Group controlId="formBasicEmail" className="flex-grow mb-3">
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="I would like to receive marketing emails from Carré"
                checked={isMarketingEmailsChecked}
                onChange={handleMarketingEmailsCheckboxChange}
              />
              {isCheckboxError && (
                <p className="text-danger">
                  Please click on the checkbox above
                </p>
              )}
            </Form.Group>

            <Button
              variant="carre-blue w-100"
              className=""
              type="submit"
              disabled={isLoading || isCheckboxError}
            >
              {isLoading ? "Subscribing..." : "Subscribe"}
            </Button>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SubscribeModal;

import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import {
  useGetVacationDetails,
  useGetVacationOrderDetails,
} from "../../../hooks/useGetVacationDetails";
import VacationOrderBook from "./VacationOrderBook";
import { useNavigate } from "react-router-dom";
import UserOrders from "./UserOrders";
import { TbBeach } from "react-icons/tb";
import { BsCurrencyDollar } from "react-icons/bs";

const VacationPage = () => {
  const { data, isLoading, error, refetch } = useGetVacationDetails();
  const { data: orderDetails } = useGetVacationOrderDetails();

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Container>
      <Card className="mt-5">
        <Card.Body>
          <Row>
            <Col sm={12} md={8} className="mb-2 mb-md-0">
              <div className="fw-medium fs-3">
                <TbBeach size={20} /> {data.total_balance?.toLocaleString()}{" "}
              </div>
              <p className="text-muted">
                {" "}
                <TbBeach
                  size={20}
                /> {data.reserved_balance?.toLocaleString()} Reserved
              </p>
              <div className="border-2 border-top w-100 text-muted">
                <span className="pt-2">
                  <BsCurrencyDollar size={20} />{" "}
                  {(
                    data.total_balance * orderDetails?.point_price
                  )?.toLocaleString("en-US", {})}{" "}
                </span>
              </div>
            </Col>
            <Col>
              <Button
                size="sm"
                onClick={() => navigate("BUY/order")}
                variant="carre-blue"
                className="mr-2 w-100 mb-3"
              >
                Buy
              </Button>

              <Button
                size="sm"
                onClick={() => navigate("SELL/order")}
                className="w-100 bg-white text-carre-blue"
              >
                Sell
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <VacationOrderBook showTransaction={true} />
      <UserOrders />
    </Container>
  );
};

export default VacationPage;

import React, { useEffect, useState } from "react";
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import { FaCalendar, FaCoins, FaExchangeAlt, FaHashtag } from "react-icons/fa"; // Import icons from react-icons library
import { MdMoreHoriz } from "react-icons/md";
import {
  useGetDividentTransfer,
  useGetVacationDividentTransfer,
} from "../../hooks/useGetDividentTransfer";
import CustomCard from "./CostumeCard";

function BalancePage({ id, viewMode }) {
  const { data, refetch, error } = useGetDividentTransfer(id);
  const { data: vacationData, refetch: vacationRefetch } =
    useGetVacationDividentTransfer(id);
  const [status, setStatus] = useState("undefined");

  useEffect(() => {
    refetch().then();
    vacationRefetch().then();
  }, [id]);

  const filteredData = () => {
    if (status === "Token") {
      return data;
    } else if (status === "Vacation Token") {
      return vacationData;
    } else {
      return [...data, ...vacationData];
    }
  };
  const getType = (dividend) => {
    if (dividend.vacation_token_price !== undefined) {
      return "Vacation";
    }
    return "Token";
  };

  if (!data || !vacationData) {
    return (
      <div>
        {error?.response?.status === 401 ? (
          <Alert className="text-center fs-sm p-2 fw-semibold">
            Log in to see more data
          </Alert>
        ) : (
          <p>Loading</p>
        )}
      </div>
    );
  }
  return (
    <div style={{ minHeight: "400px" }}>
      <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch my-2">
        <Form.Group
          controlId="sortby"
          className="d-flex align-items-center flex-shrink-0"
        >
          <Form.Label className="text-body fs-sm me-2 mb-0 pe-1 text-nowrap">
            Status:
          </Form.Label>
          <Form.Select size="sm" onChange={(e) => setStatus(e.target.value)}>
            <option value={"undefined"}>All</option>
            <option value="Token">Share</option>
            <option value="Vacation Token">Vacation Point</option>
          </Form.Select>
        </Form.Group>

        <hr className="d-none d-sm-block w-100 mx-4" />
        <div className="d-none d-sm-flex align-items-center flex-shrink-0 text-muted">
          <MdMoreHoriz className="fi-check-circle me-2" />
          <span className="fs-sm mt-n1">{filteredData()?.length} results</span>
        </div>
      </div>

      {filteredData()?.length === 0 ? (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          No dividend payment found for this property.
        </Alert>
      ) : (
        <div>
          {viewMode === "table" ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Created At</th>
                  <th>Number of Share</th>
                  <th>Amount</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {filteredData()?.map((dividend) => (
                  <tr key={dividend.id}>
                    <td>
                      {new Date(dividend.created_at).toLocaleDateString(
                        "en-US"
                      )}
                    </td>
                    <td>{dividend.no_of_token | dividend.token_qty}</td>
                    <td>{dividend.amount}</td>
                    <td>{getType(dividend)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Row>
              {filteredData()?.map((dividend) => (
                <Col key={dividend.id} sm={6} md={4} lg={3}>
                  <CustomCard
                    data={[
                      {
                        icon: <FaCalendar className="icon" />,
                        text: "Created At",
                        value: new Date(dividend.created_at).toLocaleDateString(
                          "en-US"
                        ),
                      },
                      {
                        icon: <FaHashtag className="icon" />,
                        text: "Number of Shares",
                        value: dividend.no_of_token | dividend.token_qty,
                      },
                      {
                        icon: <FaCoins className="icon" />,
                        text: "Amount",
                        value: dividend.amount,
                      },

                      {
                        icon: <FaExchangeAlt className="icon" />,
                        text: "Type",
                        value: getType(dividend),
                      },
                    ]}
                  >
                    <div className="mt-2">
                      <strong>Type:</strong> {getType(dividend)}
                    </div>
                  </CustomCard>
                </Col>
              ))}
            </Row>
          )}
        </div>
      )}
    </div>
  );
}

export default BalancePage;
